
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import OrderCreationStepMixin from "@/components/orderCreation/OrderCreationStepMixin.vue";
import OrderTile from "@/components/tileContent/OrderTile.vue";
import { Action, Getter } from "vuex-class";
import { IJobDB, IHiringReasonDB, LabelValue, MissionPositionFront, StoreAction } from "@/types";
import ListAutocompleteSelect from "@/components/ListAutocompleteSelect.vue";
import SelectAutocomplete from "@/components/SelectAutocomplete.vue";
import Tile from "@/components/tileContent/Tile.vue";
import { capitalize } from "@/helpers/commons";
import { EWorkerHabilitationType } from "@/data";

@Component({
    name: "OrderCreationJobs",
    components: { Tile, SelectAutocomplete, ListAutocompleteSelect, OrderTile },
})
export default class OrderCreationJobs extends mixins(OrderCreationStepMixin) {
    @Getter("jobOptions") jobOptions!: any;
    @Getter("getWorkerDocuments") getWorkerDocuments!: any;
    @Getter("experienceOptions") experienceOptions!: LabelValue[];
    @Getter("jobCertificationsOptions") jobCertificationsOptions!: (jobId: string) => LabelValue[];
    @Getter("isInteraction") isInteraction!: boolean;
    @Getter("contractType") contractType!: LabelValue[];

    @Getter("getJobOption") getJobOption!: any;

    @Action("actions/getLastMissionsJobs") getLastMissionsJobs!: StoreAction;
    @Action("actions/loadHiringReasons") loadHiringReasons!: StoreAction;

    certOptions: LabelValue[][] = [];
    lastJobs: any = [];
    loading = false;

    capitalize = capitalize;
    hiringReasonOptions: LabelValue[] = [];

    get filteredLastJobs() {
        // uncomment this if want only not used jobs
        // return this.lastJobs.filter((j:any) => {
        //     return !this.innerValue.positions.find((p: any) => {
        //         return p.jobName && p.jobName[0] && p.jobName[0].value === j._id;
        //     })
        // })
        return this.lastJobs;
    }

    getWorkerDocumentTypeList(type: string) {
        return this.getWorkerDocuments[type].sort((a: any, b: any) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    addHability(index: number) {
        this.innerValue.positions[index].certifications = [];
        this.$forceUpdate();
    }
    addNote(index: number) {
        if (!this.innerValue.positions[index].description) {
            this.innerValue.positions[index].description = "";
        }
        this.$forceUpdate();
    }
    removeHability(index: number) {
        this.innerValue.positions[index].certifications = undefined;
        this.$forceUpdate();
    }
    removeNote(job: MissionPositionFront) {
        job.description = undefined;
        this.$forceUpdate();
    }

    removeJob(index: number) {
        const job: string = this.innerValue.positions[index].jobId;

        if (this.innerValue.periods) {
            this.innerValue.periods
                .filter((period: any) => period.slots[job])
                .forEach((period: any) => {
                    delete period.slots[job];

                    if (Object.keys(period.slots).length > 1) return;

                    // Since we have only one entry, we can destructur twice to get key and value
                    const [[slotValue]]: Array<{ from: number; to: number }[]> = Object.values(period.slots);
                    period.slots = {
                        all: [slotValue],
                    };
                });
        }

        this.innerValue.positions.splice(index, 1);
        // At order creation workers might be undefined / null
        this.innerValue.workers = (this.innerValue.workers || [])
            .filter((worker: any) => worker.positionIndex > index)
            .forEach((worker: any) => (worker.positionIndex -= 1));
    }

    reloadCertificationsOptions(jobId: any, index: number) {
        if (typeof jobId === "number" && isNaN(jobId)) {
            this.certOptions[index].length = 0;
        } else {
            this.certOptions[index] = this.jobCertificationsOptions(jobId);
        }
        this.$forceUpdate();
    }

    changeJob(job: MissionPositionFront, newJob: LabelValue[], index: number) {
        if (typeof newJob !== "string" && newJob && newJob[0]) {
            this.reloadCertificationsOptions(newJob[0].value, index);
        } else {
            this.$set(this.certOptions, index, []);
        }
        this.$forceUpdate();
    }

    addJob(job?: IJobDB, force?: boolean) {
        if (!this.innerValue.positions) {
            this.innerValue.positions = [];
        }
        const empty: any = this.innerValue.positions.find((p: any) => p?.jobName === "" || (Array.isArray(p?.jobName) && !p?.jobName.length));
        if (empty && !force) {
            empty.jobName = job ? [this.getJobOption(job._id)] : "";
        } else {
            this.innerValue.positions.push({
                certifications: undefined,
                description: undefined,
                jobName: job ? [this.getJobOption(job._id)] : "",
                quantity: 1,
                hourlyWage: parseInt(process.env.VUE_APP_HOURLY_WAGE || ""),
                experience: "none",
                workerDocuments: {
                    [EWorkerHabilitationType.BTP_CARD]: [],
                    [EWorkerHabilitationType.HABILITATION]: [],
                    [EWorkerHabilitationType.DEGREE]: [],
                    [EWorkerHabilitationType.MEDICAL_CHECK_UP]: [],
                    [EWorkerHabilitationType.OFFICIAL_DOCUMENTS]: [],
                },
                contractType: "interim",
                hiringReason: undefined,
                justification: undefined,
            });
            // if (!this.innerValue.positions.length) {
            //     this.innerValue.positions.push({
            //         certifications:undefined,
            //         description:undefined,
            //         jobName: job ? [this.getJobOption(job._id)] : "",
            //         quantity: 1,
            //         hourlyWage: parseInt(process.env.VUE_APP_HOURLY_WAGE || ""),
            //         experience: "none"
            //     });
            // } else {
            //     this.innerValue.positions[0].jobName = job ? [this.getJobOption(job._id)] : "";
            // }
        }
        this.$forceUpdate();
    }

    async init() {
        this.loading = true;
        try {
            this.lastJobs = await this.getLastMissionsJobs();
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async getHiringReasonOptions() {
        const hiringReasons: IHiringReasonDB[] = await this.loadHiringReasons();
        hiringReasons.sort((a, b) => a.Libelle.localeCompare(b.Libelle));
        this.hiringReasonOptions = hiringReasons.map((element: IHiringReasonDB) => ({
            label: element.Libelle + "  (" + element.Code + ")",
            value: element.Code,
        }));
        this.hiringReasonOptions.unshift({ label: "Choisir un motif de recours", value: "" });
    }

    beforeMount() {
        if (!this.innerValue.positions?.length) {
            this.innerValue.positions = [];
            this.addJob();
        } else {
            this.innerValue.positions.forEach((position: MissionPositionFront, index: number) => {
                this.certOptions[index] = this.jobCertificationsOptions(position.jobId || "");
            });
        }
        this.init();
        this.getHiringReasonOptions();
    }

    toggleWorkerDocCheckbox(data: boolean, position: number, field: string) {
        console.log(data, position);
        console.log(data);

        if (data === false) {
            this.innerValue.positions[position].workerDocuments[field] = [];
            return;
        }

        this.innerValue.positions[position].workerDocuments[field] = this.getWorkerDocuments[field];
    }
}
